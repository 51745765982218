import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout.jsx';
import Button from '@components/content/button.jsx';
// Utility imports
import SEO from '@components/utility/SEO.jsx';

export const Head = () => (
	<>
        <meta name="robots" content="noindex"></meta>
	</>
)

const Index = () => (
	<Layout pageClassName="page-500">
		<SEO title='500 Error | Aurinia Alliance&reg;' description='' />
		<div className="wrapper">
			<div className="page-500-content">
				<h1>Something went wrong</h1>
				<p>Sorry! We’re experiencing technical difficulties right now. Please try again in a few hours.</p>
				<Button addedClass={'btn btn--purple btn--500'} href={'/'} label={'RETURN TO HOME'}></Button>
			</div>
		</div>
		
	</Layout>
);

export default Index;
